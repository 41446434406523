import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useState } from 'react';
import ManagerNavBar from '../../Components/NavigationBar/ManagerNavBar';
import { useTranslation } from "react-i18next";
import ManagerAdministrationBreadcrumbs from '../../Components/Breadcrumbs/Manager/ManagerAdministrationBreadcrumbs';
import Users from '../../Components/Manager/Administration/Users';
import License from '../../Components/Manager/Administration/License';
import Settings from '../../Components/Manager/Administration/Settings';
import Tests from '../../Components/Manager/Administration/Tests';
import Tubes from '../../Components/Manager/Administration/Tubes';
import Maintenance from '../../Components/Manager/Administration/Maintenance';

import GroupIcon from '@mui/icons-material/Group';
import PublicIcon from '@mui/icons-material/Public';
import SettingsIcon from '@mui/icons-material/Settings';
import ScienceIcon from '@mui/icons-material/Science';
import BiotechIcon from '@mui/icons-material/Biotech';
import BuildIcon from '@mui/icons-material/Build';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={'span'} variant={'body2'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function ManagerAdministraction() {
  const { t, i18n } = useTranslation();
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <ManagerNavBar></ManagerNavBar>
      <ManagerAdministrationBreadcrumbs></ManagerAdministrationBreadcrumbs>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            allowScrollButtonsMobile 
            variant="scrollable"
            scrollButtons
            sx={{ display: 'flex' }}
          >
            <Tab icon={<GroupIcon />} label={t("Users")} {...a11yProps(0)} />
            <Tab icon={<PublicIcon />} label={t("License")} {...a11yProps(1)} />
            <Tab icon={<SettingsIcon />} label={t("Settings")} {...a11yProps(2)} />
            <Tab  icon={<BiotechIcon />} label={t("Tests")} {...a11yProps(3)} />
            <Tab icon={<ScienceIcon />}  label={t("Tubes")} {...a11yProps(4)} />
            <Tab icon={<BuildIcon />}  label={t("Maintenance")} {...a11yProps(5)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Users></Users>
        </TabPanel>
        <TabPanel value={value} index={1}>
        <License></License>
        </TabPanel>
        <TabPanel value={value} index={2}>
        <Settings></Settings>
        </TabPanel>
        <TabPanel value={value} index={3}>
            <Tests></Tests>
        </TabPanel>
        <TabPanel value={value} index={4}>
        <Tubes></Tubes>
        </TabPanel>
        <TabPanel value={value} index={5}>
        <Maintenance></Maintenance>
        </TabPanel>
      </Box>
    </div>
  );
}

export default ManagerAdministraction;
