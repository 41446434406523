import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom'; 
import { useTranslation } from 'react-i18next';
const centerStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '40vh', // Ustawia wysokość na całą dostępną wysokość okna przeglądarki
  background:'#A5D6A740'
};

const contentStyles = {
  textAlign: 'center',
  padding: '16px', // Estetyczne odstępy
};

const Description = () => {
    const { t, i18n } = useTranslation();
  return (
    <div style={centerStyles}>
      <Container maxWidth="md">
      <Typography variant="h4" align="left" 
                      sx={{
                        mr: 2,
                        mt: 5,
                        // fontFamily: 'lato',
                        fontWeight: 700,
                        textDecoration: 'none',
                        textAlign: 'center',
                      }}
                    >
              {t("Elevate your research management today!")}
            </Typography>
        <Typography variant="h6" paragraph style={contentStyles}>
        {t("Record tests, track tubes and maintain a customer database on one user-friendly platform. Access comprehensive order history, automate protocol generation and schedule study execution. Ensure data security with user role-based access and improve team collaboration with real-time communication features. Efficiency, organization and innovation in a single lab management tool.")}
        </Typography>
      </Container>
    </div>
  );
};

export default Description;
