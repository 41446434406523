import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/system';
import Box from '@mui/material/Box';
import { useState } from 'react';
import ManagerNavBar from '../../Components/NavigationBar/ManagerNavBar';
import { useTranslation } from "react-i18next";
import ManagerDeviceBreadcrumbs from '../../Components/Breadcrumbs/Manager/ManagerDeviceBreadcrumbs';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ManagerBasicDataDevices from '../../Components/Manager/Devices/ManagerBasicDataDevices';
import BuildIcon from '@mui/icons-material/Build';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import ManagerDeviceAttachments from '../../Components/Manager/Attachments/ManagerDeviceAttachments';
import ManagerMaintenanceDevices from '../../Components/Manager/Devices/ManagerMaintenanceDevices';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography component={'span'} variant={'body2'}>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
function ManagerOrderDetails() {
  const { t, i18n } = useTranslation();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <ManagerNavBar></ManagerNavBar>
<ManagerDeviceBreadcrumbs></ManagerDeviceBreadcrumbs>

      <Box sx={{ width: '100%' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          allowScrollButtonsMobile 
          variant="scrollable"
          scrollButtons
          sx={{ display: 'flex' }}
        >
          <Tab icon={<PrecisionManufacturingIcon />} label={t('Basic data')} {...a11yProps(0)} />
          <Tab icon={<AttachFileIcon />} label={t('Attachments')} {...a11yProps(1)} />
          <Tab icon={<BuildIcon />} label={t('Maintenance')} {...a11yProps(2)} />
        </Tabs>
        <TabPanel value={value} index={0}>
<ManagerBasicDataDevices></ManagerBasicDataDevices>
        </TabPanel>
        <TabPanel value={value} index={1} >
        <Container maxWidth="md">
        <ManagerDeviceAttachments></ManagerDeviceAttachments>
</Container>
        </TabPanel>
        <TabPanel value={value} index={2} >
<ManagerMaintenanceDevices></ManagerMaintenanceDevices>
        </TabPanel>
      </Box>
    </div>
  );
}

export default ManagerOrderDetails;
