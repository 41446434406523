import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Grid,
  Container,
  Typography,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardContent,
  CardActions,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import supabase from '../../../supabaseClient';
import { useParams } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styled from 'styled-components';
import { format } from 'date-fns';
import ScienceIcon from '@mui/icons-material/Science';


const DateInput = styled.input`
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
`;

function ManagerTubesOrder() {
  const { t, i18n } = useTranslation();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [status, setStatus] = useState('');
  const [dictionaryTubes, setDictionaryTubes] = useState([]);
  const [fetchError, setFetchError] = useState(null);
  const [tubes, setTubes] = useState([]);
  const [selectedDateTime, setSelectedDateTime] = useState('');
  const { id } = useParams();

  const [userID, setUserID] = useState('');
  const [idConfig, setIdConfiguration] = useState('');

    // Dialog state
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [selectedTube, setSelectedTube] = useState();


  useEffect(() => {
    const checkSession = async () => {
      const { data } = await supabase.auth.getSession();
      if (data.session) {
        setUserID(data.session.user.id);
        fetchData(data.session.user.id);
      }
    };
    checkSession();
  }, []);

  const handleFetchDataStatus = async (idConfig, id) => {
    const { data, error } = await supabase
      .from('orders')
      .select('status')
      .eq('id', id)
      .eq('id_configuration', idConfig)
      .single();
    if (error) {
      console.error(error);
    }
    if (data) {
      setStatus(data.status);
    }
  };

  const fetchData = async (userId) => {
    const { data: profileData, error: profileError } = await supabase
      .from('profiles')
      .select('id_configuration')
      .eq('id', userId)
      .single();
    if (profileError) {
      console.error(profileError);
    } else if (profileData) {
      setIdConfiguration(profileData.id_configuration);
    }
  };

  const handleDateTimeChange = (event) => {
    setSelectedDateTime(event.target.value);
  };

  const [formattedDate, setFormattedDate] = useState('');

  useEffect(() => {
    if (selectedDateTime) {
      const formattedDate = format(new Date(selectedDateTime), 'dd/MM/yyyy HH:mm');
      setFormattedDate(formattedDate);
    }
  }, [selectedDateTime]);

  const handleFetchDictionaryTubes = async (idConfig) => {
    const { data, error } = await supabase
      .from('typeOfTubes')
      .select()
      .eq('id_configuration', idConfig)
      .is('isDeleted', null);
    if (error) {
      console.error(error);
    }
    if (data) {
      setDictionaryTubes(data);
    }
  };

  useEffect(() => {
    if (idConfig) {
      fetchTubes(idConfig, id);
      handleFetchDictionaryTubes(idConfig);
      handleFetchDataStatus(idConfig, id);
    }
  }, [idConfig, id]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    await insertTube();
  };

  const DeleteTube = async (event, cellValues) => {
    const { data, error } = await supabase
      .from('tubes')
      .update({ isDeleted: 1 }) // Aktualizacja zamiast usuwania
      .eq('id', cellValues.id);
  
    handleClickAlert();
    fetchTubes(idConfig, id);
  
    if (error) {
      console.error(error);
    }
  };
  
  const insertTube = async () => {
    const { data, error } = await supabase.from('tubes').insert([
      {
        id_configuration: idConfig,
        name: name,
        description: description,
        status: status,
        id_order: id,
        user: userID,
        date: formattedDate,
      },
    ]);
    handleClickAlert();
    fetchTubes(idConfig, id);
    if (error) {
      console.error(error);
    }
    if (data) {
      setName('');
      setDescription('');
      setStatus('');
    }
  };

  const fetchTubes = async (idConfiguration, id) => {
    const { data, error } = await supabase
      .from('tubes')
      .select()
      .eq('id_order', id)
      .eq('id_configuration', idConfiguration)
      .is('isDeleted', null); // Pobieranie tylko rekordów, które nie są oznaczone jako usunięte
  
    if (error) {
      console.error(error);
      setTubes([]);
      setFetchError(t('No Tubes'));
    }
    if (data) {
      setTubes(data);
      setFetchError(null);
    }
  };

  const handleTubesDictionary = (event) => {
    const value = event.target.value;
    setName(value.name);
    setDescription(value.remarks);
  };

  const [open, setOpen] = useState(false);

  const handleClickAlert = () => {
    setOpen(true);
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };


  const handleEditClick = (event, cellValues) => {
    setSelectedTube(cellValues.id);
    setName(cellValues.name);
    setDescription(cellValues.description);
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  const handleSaveEdit = async () => {
    await supabase
      .from('tubes')
      .update({ name, description })
      .eq('id', selectedTube);

      fetchTubes(idConfig, id);

    setOpenEditDialog(false);

    handleClickAlert();
  };

  return (
    <div>
      <Container maxWidth="md">
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6" fontWeight="bold">
              {t('Add')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography></Typography>
            <form
              onSubmit={handleSubmit}
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              <Container maxWidth="md">
                <Typography variant="h4" align="center" gutterBottom>
                  <p></p>
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel id="dictionary-select-label">{t('Select type of tube')}</InputLabel>
                      <Select
                        labelId="dictionary-select-label"
                        id="dictionary-select"
                        onChange={handleTubesDictionary}
                        label={t('Select type of tube')}
                        value={dictionaryTubes.find((item) => item.name === name) || ''}
                      >
                        {dictionaryTubes.map((dictionaryTube) => (
                          <MenuItem key={dictionaryTube.id} value={dictionaryTube}>
                            {dictionaryTube.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  {/* <Grid item xs={12} sm={6}>
                    <TextField
                      label={t('Name')}
                      value={name}
                      onChange={(event) => setName(event.target.value)}
                      style={{ marginRight: '10px' }}
                      fullWidth
                      disabled
                    />
                  </Grid> */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      type="datetime-local"
                      id="Date"
                      value={selectedDateTime}
                      onChange={handleDateTimeChange}
                      required
                      fullWidth
                      label={t('Date')}
                      focused
                      style={{ marginRight: '10px' }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t('Description')}
                      value={description}
                      onChange={(event) => setDescription(event.target.value)}
                      style={{ marginRight: '10px' }}
                      fullWidth
                      multiline
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-end">
                      <Button
                        type="submit"
                        variant="contained"
                        disabled={status === 'completed'}
                        color="primary"
                        style={{ minWidth: 'auto' }}
                      >
                        {t('Submit')}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
                <div></div>
                <Snackbar open={open} autoHideDuration={2000} onClose={handleCloseAlert}>
                  <Alert severity="success">{t('Updated!')}</Alert>
                </Snackbar>
              </Container>
            </form>
          </AccordionDetails>
        </Accordion>

        </Container>
            <Container maxWidth="md">
              <div>
                {fetchError && <p>{fetchError}</p>}
                {tubes.length > 0 && (
                  <div>
                    <p> </p>
                    <Grid container spacing={2}>
                      {tubes
                        .sort((a, b) => b.id - a.id)
                        .map((tubeItem) => (
                          <Grid item xs={12} sm={6} md={12} lg={12} key={tubeItem.id}>
                            <Card>
                              <CardContent>
                                <div onClick={(event) => handleEditClick(event, tubeItem)} style={{ cursor: 'pointer' }}>
                                <Divider textAlign="right">{t('ID')} {tubeItem.id} </Divider>
                                <Grid container alignItems="center">
                                <ScienceIcon style={{ color: 'green', marginRight: '10px', fontSize: 'large' }} />
                                  <Typography variant="h6">{tubeItem.name}</Typography>
                                </Grid>
                                <Typography variant="body2">{tubeItem.description}</Typography>
                                <Typography variant="body2">{t('Date')}: {tubeItem.date}</Typography>
                                </div>
                              </CardContent>
                              <CardActions style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <Button
                            color="primary"
                            onClick={(event) => handleEditClick(event, tubeItem)}
                          >
                            {t('Edit')}
                          </Button>
                                <Button
                                  color="error"
                                  disabled={status === 'completed'}
                                  onClick={(event) => {
                                    DeleteTube(event, tubeItem);
                                  }}
                                >
                                  {t('Delete')}
                                </Button>
                              </CardActions>
                            </Card>
                          </Grid>
                        ))}
                    </Grid>
                  </div>
                )}
              </div>
            </Container>

  

            {/* Edit Dialog */}
            <Dialog open={openEditDialog} onClose={handleCloseEditDialog}>
        <DialogTitle>{t('Edit Tube')}</DialogTitle>
        <DialogContent>
        <p></p>
          <TextField
            label={t('Name')}
            value={name}
            onChange={(event) => setName(event.target.value)}
            style={{ marginBottom: '10px' }}
            fullWidth
          />
          <TextField
            label={t('Description')}
            value={description}
            onChange={(event) => setDescription(event.target.value)}
            style={{ marginBottom: '10px' }}
            fullWidth
            multiline
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditDialog} color="primary">
            {t('Cancel')}
          </Button>
          <Button onClick={handleSaveEdit} color="primary">
            {t('Save')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ManagerTubesOrder;
