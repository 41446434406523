
import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ManagerNavBar from '../../Components/NavigationBar/ManagerNavBar';
import { useTranslation } from "react-i18next";
import ManagerReportsBreadcrumbs from '../../Components/Breadcrumbs/Manager/ManagerReportsBreadcrumbs';
import ManagerStatusReport from '../../Components/Reports/ManagerStatusReport';
import ManagerContractorReport from '../../Components/Reports/ManagerContractorReport';
import ManagerAllTubesReport from '../../Components/Reports/ManagerAllTubesReport';
import ManagerAllTestesReport from '../../Components/Reports/ManagerAllTestesReport';
import ManagerMaintenanceReport from '../../Components/Reports/ManagerMaintenanceReport';

function ManagerReports() {
    const { t, i18n } = useTranslation();

  return (
    <div>
        <ManagerNavBar></ManagerNavBar>
        <ManagerReportsBreadcrumbs></ManagerReportsBreadcrumbs>

        <p></p>
        <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" fontWeight="bold">{t('Status of orders')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography></Typography>
          <ManagerStatusReport></ManagerStatusReport>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" fontWeight="bold">{t('Report realization of orders for the contractors')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography></Typography>
          <ManagerContractorReport></ManagerContractorReport>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" fontWeight="bold">{t('Tubes')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography></Typography>
        <ManagerAllTubesReport></ManagerAllTubesReport>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" fontWeight="bold">{t('Tests')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography></Typography>
         <ManagerAllTestesReport></ManagerAllTestesReport>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" fontWeight="bold">{t('Maintenance')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography></Typography>
         <ManagerMaintenanceReport></ManagerMaintenanceReport>
        </AccordionDetails>
      </Accordion>


      
    </div>
  );
}

export default ManagerReports;
