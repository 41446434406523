import React, { useState, useEffect } from 'react';
import {
    Card,
    CardContent,
    Typography,
    Grid,
    TextField,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Switch,Rating,
    Box,Divider
} from '@mui/material';
import supabase from '../../../supabaseClient';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import FilterListIcon from '@mui/icons-material/FilterList';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';

const UnavailableDevices = () => {
    const [devices, setDevices] = useState([]);
    const [filteredDevices, setFilteredDevices] = useState([]);
    const [searchName, setSearchName] = useState('');
    const [searchNumber, setsearchNumber] = useState('');
    const [searchDevice, setsearchDevice] = useState('');
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const [userID, setUserID] = useState('');
    const [idConfig, setIdConfiguration] = useState('');
    const [isFilterPopupOpen, setIsFilterPopupOpen] = useState(false);
    
    useEffect(() => {
        const checkSession = async () => {
            const { data } = await supabase.auth.getSession();
            if (data.session) {
                setUserID(data.session.user.id);
                fetchData(data.session.user.id);
            }
        };
        checkSession();
    }, []);

    const fetchData = async (userId) => {
        const { data: profileData, error: profileError } = await supabase
            .from('profiles')
            .select('id_configuration')
            .eq('id', userId)
            .single();
        if (profileError) {
            console.log(profileError);
        } else if (profileData) {
            setIdConfiguration(profileData.id_configuration);
        }
    };

    useEffect(() => {
        if (idConfig) {
            fetchDevices(idConfig);
        }
    }, [idConfig]);

    useEffect(() => {
        let filteredData = devices;

        if (searchName !== '') {
            filteredData = filteredData.filter((venue) =>
                venue.name.toLowerCase().includes(searchName.toLowerCase())
            );
        }
        if (searchNumber !== '') {
            filteredData = filteredData.filter((venue) =>
                venue.id.toString().includes(searchNumber)
            );
        }



        setFilteredDevices(filteredData);
    }, [devices, searchName, searchNumber]);

    const fetchDevices = async (idConfig) => {
        const { data, error } = await supabase
            .from('devices')
            .select()
            .eq('id_configuration', idConfig)
            .eq('archived', 1);
        if (error) {
            console.error(error);
        } else {
            setDevices(data);
        }
    };

    const handleButtonClickDetails = (venue) => {
        navigate('/ManagerDeviceDetails/' + venue.id);
    };

    const addNewDevice = () => {
        navigate('/AddNewDevice');
    };

    const applyFilters = () => {
        // Domyślnie ustawiamy showIneffective na true, aby wyświetlić wszystkie miejsca
        let filteredData = devices;
        if (searchName !== '') {
            filteredData = filteredData.filter((venue) =>
                venue.name.toLowerCase().includes(searchName.toLowerCase())
            );
        }
        if (searchNumber !== '') {
            filteredData = filteredData.filter((venue) =>
                venue.id.toString().includes(searchNumber)
            );
        }



        setFilteredDevices(filteredData);
        setIsFilterPopupOpen(false);
    };


    return (
        <div>
            <Button
                style={{ marginLeft: '20px', marginBottom: '20px' }}
                type="submit"
                variant="contained"
                color="primary"
                onClick={addNewDevice}
                startIcon={<AddIcon />}
            >
                {t('Add')}
            </Button>
            <Button
                style={{ marginLeft: '20px', marginBottom: '20px' }}
                variant="contained"
                color="primary"
                onClick={() => setIsFilterPopupOpen(true)}
                startIcon={<FilterListIcon />}
            >
                {t('Open Filter')}
            </Button>
            <Dialog open={isFilterPopupOpen} onClose={() => setIsFilterPopupOpen(false)}>
                <DialogTitle>{t('Filter')}</DialogTitle>
                <DialogContent>
                    <p></p>
                    <div style={{ marginBottom: '16px' }}>
                        <TextField
                            label={t('Search by number')}
                            variant="outlined"
                            value={searchNumber}
                            onChange={(e) => setsearchNumber(e.target.value)}
                            style={{ marginBottom: '8px' }}
                        />
                    </div>
                    <div style={{ marginBottom: '16px' }}>
                        <TextField
                            label={t('Search by name')}
                            variant="outlined"
                            value={searchName}
                            onChange={(e) => setSearchName(e.target.value)}
                            style={{ marginBottom: '8px' }}
                        />
                    </div>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={applyFilters}
                        style={{ marginTop: '16px' }}
                    >
                        {t('Apply Filters')}
                    </Button>
                </DialogContent>
            </Dialog>

            <Grid container spacing={3}>
                {filteredDevices.sort((a, b) => b.id - a.id).map((venue) => (
                    <Grid key={venue.id} item xs={12} sm={6} md={4} lg={3}>
                        <Card>
                            <CardContent>
                                <div onClick={() => handleButtonClickDetails(venue)} style={{ cursor: 'pointer' }}>
                            <Divider textAlign='right'>{t('ID')} {venue.id} </Divider>
                                {/* <Typography variant="h6" gutterBottom>
                                    ID: {venue.id}
                                </Typography> */}
                                <Typography variant="h6" gutterBottom>
                                <PrecisionManufacturingIcon style={{ color: 'green', marginRight: '10px', fontSize: 'large' }} /> {venue.name}
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    {t('Manufacturer')} : {venue.manufacturer}
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    {t('Serial number')} : {venue.serial_number}
                                </Typography>
                                <Rating
                                    name="rating"
                                    value={venue.rating}
                                    size="large"
                                    max={5}
                                    readOnly
                                />
                                </div>
                                <p></p>
                                <Box display="inline-block" marginRight={2}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleButtonClickDetails(venue)}
                                    startIcon={<EditIcon />}
                                >
                                    {t('details')}
                                </Button>
                                </Box>

                                <Box display="inline-block" marginRight={2}>

                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
};

export default UnavailableDevices;
