import React from 'react';
import { Container, Typography, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const footerStyles = {
  backgroundColor: 'white', // Niebieskie tło
  color: '#4CAF50', // Biały tekst
  padding: '16px', // Odstępy między elementami
};

const linkStyles = {
  color: '#4CAF50', // Kolor linków
  textDecoration: 'none',
  marginRight: '16px', // Odstęp między linkami
  '&:hover': {
    textDecoration: 'underline',
  },
};

function Footer() {
    const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const Home =  () => {
    navigate('/home')
    }
    const InfoContact =  () => {
      navigate('/infoContact')
      }
    const faq =  () => {
        navigate('/faq')
        }
    const terms =  () => {
          navigate('/termsAndConditions')
          }


  return (
    <footer style={footerStyles}>
      <Container maxWidth="lg">
        <Typography variant="body2" align="center">
        {t("Copyright © 2023 Streamlinelab - all rights reserved.")}
        </Typography>
        <Typography variant="body2" align="center">
          <Link style={linkStyles} onClick={InfoContact}>
          {t("Contact")}
          </Link>
          <Link style={linkStyles} onClick={faq}>
          {t("FAQ")}
          </Link>
          <Link style={linkStyles} onClick={terms}>
          {t("Terms and conditions")}
          </Link>
        </Typography>
      </Container>
    </footer>
  );
}

export default Footer;
