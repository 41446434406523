import React from 'react';
import { useState,useEffect } from 'react';
import { TextField, Button, Grid, Container, Typography, Select, MenuItem,Box,Accordion, AccordionSummary, AccordionDetails,Rating,Checkbox } from '@mui/material';
import supabase from '../../../supabaseClient';
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useNavigate } from "react-router-dom"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TimelineDevice from './TimelineDevice';

const ManagerBasicDataDevices = () => {
    const navigate = useNavigate()
    const {id} = useParams()
const { t, i18n } = useTranslation();
const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [author, setAuthor] = useState('');
  const [serial_number, setSerialNumber] = useState('');
  const [manufacturer, setManufacturer] = useState('');
  const [warranty_date, setWarrantyDate] = useState('');
  const [purchase_date, setPurchaseDate] = useState('');
  const [added_date, setAddedDate] = useState('');
  const [userID, setUserID] = useState('');
  const [idConfig, setIdConfiguration] = useState('');
  const [rating, setRating] = useState(null);
  const [archived, setArchived] = useState(null);

  const handleRatingChange = (event, newRating) => {
    setRating(newRating);
  };



  useEffect(() => {
      const checkSession = async () => {
          const { data } = await supabase.auth.getSession();
          if (data.session) {
              setUserID(data.session.user.id);
              fetchData(data.session.user.id);
          }
      };
      checkSession();
  }, []);

  const fetchData = async (userId) => {
      const { data: profileData, error: profileError } = await supabase
          .from('profiles')
          .select('id_configuration')
          .eq('id', userId)
          .single();
      if (profileError) {
          console.log(profileError);
      } else if (profileData) {
          setIdConfiguration(profileData.id_configuration);
      }
  }

  useEffect(() => {
      if (idConfig) {
        FetchDevice(idConfig);
      }
  }, [idConfig]);


  const FetchDevice = async(idConfig)=>{
    const{data,error} =  await supabase
    .from('devices')
    .select()
    .eq('id',id)
    .eq('id_configuration', idConfig) 
    .single()
    if(error){
        console.log(error)
        navigate('/home')
    }if(data){
        setName(data.name);
        setDescription(data.description);
        setSerialNumber(data.serial_number);
        setManufacturer(data.manufacturer);
        setRating(data.rating);
        setArchived(data.archived); 
        setAuthor(data.author); 
        setPurchaseDate(data.purchase_date); 
        setAddedDate(data.added_date); 
        setWarrantyDate(data.warranty_date); 
    }
}

const updateContrator = async () => {
    const { data, error } = await supabase
      .from('devices')
      .update({
        'name': name,
        'description': description,
        'manufacturer': manufacturer,
        'serial_number': serial_number,
        'rating': rating,
        'archived': archived,
        'purchase_date':purchase_date,
        'warranty_date':warranty_date
      })
      .eq('id', id);
  
    handleClickAlert();
  };
  

  //Delete
  const DeleteContractor = async()=>{
    const{data,error} =  await supabase
    .from('devices')
    .update({'archived':1})
    .eq('id',id);
handleClickAlert();
navigate('/Devices');
if(error){
    console.log(error)
}if(data){
}
}

const handleSubmit = (event) => {
    event.preventDefault();
    updateContrator()
  };

 //alert configuration
 const [open,setOpen] =useState(null)

 const handleClickAlert = () => {
   setOpen(true);
 };
 
 const handleCloseAlert = (event, reason) => {
   if (reason === 'clickaway') {
     return;
   }
   setOpen(false);
 };


 const handlePurchaseDate = (event) => {
    setPurchaseDate(event.target.value); // Przechowuje datę i godzinę w formacie YYYY-MM-DDTHH:MM
  };
  const handleWarranty = (event) => {
    setWarrantyDate(event.target.value); // Przechowuje datę i godzinę w formacie YYYY-MM-DDTHH:MM
  };
  const handleAddedDate = (event) => {
    setAddedDate(event.target.value); // Przechowuje datę i godzinę w formacie YYYY-MM-DDTHH:MM
  };
 
    return (
        <div>
         <Container maxWidth="md">
        <Typography variant="h4" align="center" gutterBottom>
       <p></p>
        </Typography>
        <form onSubmit={handleSubmit} >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                name="name"
                label={t("Name")}
                value={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="description"
                label={t("Description")}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="manufacturer"
                label={t("Manufacturer")}
                value={manufacturer}
                onChange={(e) => setManufacturer(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="serial_number"
                label={t("Serial number")}
                value={serial_number}
                onChange={(e) => setSerialNumber(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
                        <TextField
                        type="datetime-local"
                        id="purchase_date"
                        value={purchase_date}
                        onChange={handlePurchaseDate}
                        fullWidth
                        label={t('Purchase date')}
                        focused
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                        type="datetime-local"
                        id="warranty_date"
                        value={warranty_date}
                        onChange={handleWarranty}
                        fullWidth
                        label={t('Warranty date')}
                        focused
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                        type="datetime-local"
                        id="added_date"
                        value={added_date}
                        onChange={handleAddedDate}
                        fullWidth
                        label={t('Date of creation')}
                        disabled
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
              <TextField
                name="Author"
                label={t('Author')}
                value={author}
                fullWidth
                multiline
                onChange={(e) => setAuthor(e.target.value)}
                disabled
              />
            </Grid>
                    <Grid item xs={12} sm={6}>
              <Rating
                name="rating"
                value={rating}
                onChange={handleRatingChange}
                size="large"
                max={5}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
                <label>
                    {t('Archived')}
                    <Checkbox
                    checked={archived === 1}
                    onChange={() => setArchived((prev) => (prev === 1 ? null : 1))}
                    />
                </label>
                </Grid>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="flex-end" marginTop={1}>
                                <Button
                                  type="submit"
                                  variant="contained"
                                  color="primary"
                                >
                                  {t("Submit")}
                                </Button>
                          <Box marginLeft={1}> 
                            <Button
                                  type="error"
                                  variant="contained"
                                  color="error"
                                  onClick={DeleteContractor} 
                                >
                                  {t("Delete")}
                                </Button>
                          </Box>
              </Box>
</Grid>
          </Grid>
          <div>
    </div>
        </form>
<p></p>
<div>
    <TimelineDevice></TimelineDevice>
</div>
        <Snackbar open={open}
            autoHideDuration={2000}
            onClose={handleCloseAlert}>
          <Alert severity="success"> {t("Updated!")}</Alert>
          </Snackbar>
      </Container>
         </div>
      );
};
export default ManagerBasicDataDevices;