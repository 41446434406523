import React from 'react';
import { useTranslation } from "react-i18next";

import { Accordion, AccordionSummary, AccordionDetails, Typography,Grid,Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ManagerStatusReport from '../Reports/ManagerStatusReport';
import ManagerContractorReport from '../Reports/ManagerContractorReport';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import EndingMaintenanceDevice from '../Manager/Devices/EndingMaintenanceDevice';
import WorkerNavBar from '../NavigationBar/WorkerNavBar';

const WorkerMainView = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const addNeworder = () => {
    navigate('/WorkerAddNewOrder');
  };

  return (
    <div>
 <WorkerNavBar></WorkerNavBar>    
<p></p>
      <Button
        style={{ marginLeft: '20px', marginBottom: '20px' }}
        type="submit"
        variant="contained"
        color="primary"
        size="large"
        onClick={addNeworder}
        startIcon={<AddIcon />}
      >
      {t('New order')}
      </Button>

<p></p>
        <Grid container spacing={4}>
      <Grid item xs={12} sm={7}>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6" fontWeight="bold">{t('Report realization of orders for the contractors')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography></Typography>
          <ManagerContractorReport></ManagerContractorReport>
        </AccordionDetails>
      </Accordion>
      </Grid>

      <Grid item xs={12} sm={5}>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6" fontWeight="bold">{t('Status of orders')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography></Typography>
          <ManagerStatusReport></ManagerStatusReport>
        </AccordionDetails>
      </Accordion>
      </Grid>

      <Grid item xs={12} sm={12}>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6" fontWeight="bold">{t('Upcoming completion of reviews')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography></Typography>
          <EndingMaintenanceDevice></EndingMaintenanceDevice>
        </AccordionDetails>
      </Accordion>
      </Grid>


    </Grid>
  </div>
  );
};

export default WorkerMainView;
