import React, { useState, useEffect } from 'react';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import supabase from '../../../supabaseClient';
import { useParams } from "react-router-dom";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import BuildIcon from '@mui/icons-material/Build';
import { useTranslation } from "react-i18next";

const TimelineDevice = () => {
  const [events, setEvents] = useState([]);
  const [userID, setUserID] = useState('');
  const [idConfig, setIdConfiguration] = useState('');
  const {id} = useParams();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const checkSession = async () => {
        const { data } = await supabase.auth.getSession();
        if (data.session) {
            setUserID(data.session.user.id);
            fetchData(data.session.user.id);
        }
    };
    checkSession();
}, []);
const fetchData = async (userId) => {
    const { data: profileData, error: profileError } = await supabase
      .from('profiles')
      .select('id_configuration')
      .eq('id', userId)
      .single();
    if (profileError) {
      console.error(profileError);
    } else if (profileData) {
      setIdConfiguration(profileData.id_configuration);
    }
  };

  useEffect(() => {
    const fetch = async () => {
      // Pobierz dane z tabeli 'device'
      let { data: devices, error: devicesError } = await supabase
        .from('devices')
        .select()
        .eq('id',id)


      // Pobierz dane z tabeli 'maintenance'
      let { data: maintenances, error: maintenancesError } = await supabase
        .from('maintenance')
        .select()
        .order('id', { ascending: true })
        .eq('id_device', id)
        .is('isDeleted', null);

      if (devicesError || maintenancesError) {
        console.error(devicesError || maintenancesError);
        return;
      }

      // Łączenie danych w jedną listę
      const timelineEvents = devices.map(devices => ({
        ...devices,
        label: t("Purchase of the device"),
        icon:  <ShoppingCartIcon />
      })).concat(maintenances.map(maintenance => ({
        ...maintenance,
        label: t("Maintenance"),
        icon: <BuildIcon/>
      })));

      setEvents(timelineEvents);
    };

    fetch();
  }, []);

  return (
    <VerticalTimeline>
      {events.map((event, index) => (
        <VerticalTimelineElement
          key={index}
        //   date={event.date || event.purchase_date}
         date={event.date}
          iconStyle={{ background: '#4CAF50', color: '#fff' }}
          icon={<i className="material-icons">{event.icon}</i>}
        >
          <h3 className="vertical-timeline-element-title">{event.label}</h3>
          <p>{event.name}</p>
        </VerticalTimelineElement>
      ))}
    </VerticalTimeline>
  );
};

export default TimelineDevice;
