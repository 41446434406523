import React from 'react';
import PasswordChange from '../../Components/Common/PasswordChange';
import ManagerProfileBreadcrumbs from '../../Components/Breadcrumbs/Manager/ManagerProfileBreadcrumbs';
import WorkerNavBar from '../../Components/NavigationBar/WorkerNavBar';
const WorkerProfile = () => {
    return (
        <div>
            <WorkerNavBar></WorkerNavBar>
            <ManagerProfileBreadcrumbs></ManagerProfileBreadcrumbs>
         <PasswordChange></PasswordChange>
      </div>
      );
};

export default WorkerProfile;