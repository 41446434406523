import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Grid,
  Container,
  Typography,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardContent,
  CardActions,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import supabase from '../../../supabaseClient';
import { useParams } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styled from 'styled-components';
import { format } from 'date-fns';
import SellIcon from '@mui/icons-material/Sell';
import BiotechIcon from '@mui/icons-material/Biotech';

const DateInput = styled.input`
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
`;

function ManagerTestsOrder() {
  const { t, i18n } = useTranslation();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [cost, setCost] = useState(0);
  const [result, setResult] = useState(0);
  const [unit, setUnit] = useState('');
  const [standardFrom, setStandardFrom] = useState(0);
  const [standardTo, setStandardTo] = useState(0);
  const [status, setStatus] = useState('');
  const [dictionaryTests, setDictionaryTests] = useState([]);
  const [fetchError, setFetchError] = useState(null);
  const [tests, setTests] = useState([]);
  const [selectedDateTime, setSelectedDateTime] = useState('');
  const [fullTotalTask, setFullTotalTask] = useState(0);
  const [tubes, setTubes] = useState([]); // State to store tubes
  const [selectedTube, setSelectedTube] = useState('');
  const [selectedTubeInEdit, setSelectedTubeInEdit] = useState('');

  const { id } = useParams();

  const [userID, setUserID] = useState('');
  const [idConfig, setIdConfiguration] = useState('');

    // Dialog state
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [selectedTest, setSelectedTest] = useState();

  useEffect(() => {
    const checkSession = async () => {
      const { data } = await supabase.auth.getSession();
      if (data.session) {
        setUserID(data.session.user.id);
        fetchData(data.session.user.id);
      }
    };
    checkSession();
  }, []);

  const handleFetchDataStatus = async (idConfig, id) => {
    const { data, error } = await supabase
      .from('orders')
      .select('status')
      .eq('id', id)
      .eq('id_configuration', idConfig)
      .single();
    if (error) {
      console.error(error);
    }
    if (data) {
      setStatus(data.status);
    }
  };

  const fetchData = async (userId) => {
    const { data: profileData, error: profileError } = await supabase
      .from('profiles')
      .select('id_configuration')
      .eq('id', userId)
      .single();
    if (profileError) {
      console.error(profileError);
    } else if (profileData) {
      setIdConfiguration(profileData.id_configuration);
    }
  };

  const handleDateTimeChange = (event) => {
    setSelectedDateTime(event.target.value);
  };

  const [formattedDate, setFormattedDate] = useState('');

  useEffect(() => {
    if (selectedDateTime) {
      const formattedDate = format(new Date(selectedDateTime), 'dd/MM/yyyy HH:mm');
      setFormattedDate(formattedDate);
    }
  }, [selectedDateTime]);

  const handleFetchDictionaryTests = async (idConfig) => {
    const { data, error } = await supabase
      .from('typeOfTests')
      .select()
      .eq('id_configuration', idConfig)
      .is('isDeleted', null);
    if (error) {
      console.error(error);
    }
    if (data) {
      setDictionaryTests(data);
    }
  };

  const fetchTubes = async (idConfiguration, id) => {
    const { data, error } = await supabase
      .from('tubes')
      .select()
      .eq('id_order', id)
      .eq('id_configuration', idConfiguration)
      .is('isDeleted', null);

    if (error) {
      console.error(error);
      setTubes([]);
    } else {
      setTubes(data);
    }
  };

  useEffect(() => {
    if (idConfig) {
      fetchTests(idConfig, id);
      fetchTubes(idConfig, id);
      handleFetchDictionaryTests(idConfig);
      handleFetchDataStatus(idConfig, id);
    }
  }, [idConfig, id]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    await insertTest();
  };

  const DeleteTest = async (event, cellValues) => {
    const { data, error } = await supabase.from('tests').delete().eq('id', cellValues.id);
    handleClickAlert();
    fetchTests(idConfig, id);
    if (error) {
      console.error(error);
    }
    if (data) {
      // Handle successful deletion if needed
    }
  };

  const insertTest = async () => {
    const { data, error } = await supabase.from('tests').insert([
      {
        id_configuration: idConfig,
        name: name,
        description: description,
        id_order: id,
        user: userID,
        cost:cost,
        unit:unit,
        standardFrom:standardFrom,
        standardTo:standardTo,
        result:result,
        date:formattedDate,
        idTube: selectedTube
      },
    ]);
    handleClickAlert();
    fetchTests(idConfig, id);
    if (error) {
      console.error(error);
    }
    if (data) {
      setName('');
      setDescription('');
    }
  };

  const fetchTests = async (idConfiguration, id) => {
    const { data, error } = await supabase
      .from('tests')
      .select(`
      *,
      tubes (
        name
      )
    `)
      .eq('id_order', id)
      .eq('id_configuration', idConfiguration);
    if (error) {
      console.error(error);
      setTests([]);
      setFetchError(t('No Tests'));
    }
    if (data) {
      setTests(data);
      setFetchError(null);
    }
  };

  const handleTestsDictionary = (event) => {
    const value = event.target.value;
    setName(value.name);
    setDescription(value.description);
    setUnit(value.unit);
    setCost(value.cost);
    setStandardFrom(value.standardFrom)
    setStandardTo(value.standardTo)
  };

  const [open, setOpen] = useState(false);

  const handleClickAlert = () => {
    setOpen(true);
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };


  const handleEditClick = (event, cellValues) => {
    setSelectedTest(cellValues.id);
    setName(cellValues.name);
    setDescription(cellValues.description);
    setUnit(cellValues.unit);
    setStandardTo(cellValues.standardTo);
    setStandardFrom(cellValues.standardFrom);
    setCost(cellValues.cost);
    setResult(cellValues.result);
    setSelectedTubeInEdit(cellValues.idTube); 


    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  const handleSaveEdit = async () => {
    await supabase
      .from('tests')
      .update({ name, description,unit,standardFrom,standardTo,cost,result,idTube: selectedTubeInEdit,})
      .eq('id', selectedTest);

      fetchTests(idConfig, id);

    setOpenEditDialog(false);

    handleClickAlert();
  };

  useEffect(() => {
    if (tests) {
        const calculatedFullTotal = tests.reduce((acc, testItem) => acc + testItem.cost, 0);
        setFullTotalTask(calculatedFullTotal);
    }
}, [tests]);

  return (
    <div>
      <Container maxWidth="md">
      <div>
                <p></p>
                <div>
                    {fetchError && <p>{fetchError}</p>}
                    {tests && (
                        <div style={{ textAlign: 'right' }}>
                            <div>
                                <Typography variant="h6" color='green'>
                                    {t('Summary')}: {fullTotalTask} <SellIcon fontSize="medium" />
                                </Typography>
                            </div>
                        </div>
                    )}
                </div>
            </div>


        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6" fontWeight="bold">
              {t('Add')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography></Typography>
            <form
              onSubmit={handleSubmit}
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              <Container maxWidth="md">
                <Typography variant="h4" align="center" gutterBottom>
                  <p></p>
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel id="dictionary-select-label">{t('Select type of test')}</InputLabel>
                      <Select
                        labelId="dictionary-select-label"
                        id="dictionary-select"
                        onChange={handleTestsDictionary}
                        label={t('Select type of test')}
                        value={dictionaryTests.find((item) => item.name === name) || ''}
                      >
                        {dictionaryTests.map((dictionaryTest) => (
                          <MenuItem key={dictionaryTest.id} value={dictionaryTest}>
                            {dictionaryTest.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  {/* <Grid item xs={12} sm={6}>
                    <TextField
                      label={t('Name')}
                      value={name}
                      onChange={(event) => setName(event.target.value)}
                      style={{ marginRight: '10px' }}
                      fullWidth
                      disabled
                    />
                  </Grid> */}
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                      <InputLabel id="tube-select-label">{t('Tube')}</InputLabel>
                      <Select
                        labelId="tube-select-label"
                        id="tube-select"
                        value={selectedTube}
                        onChange={(event) => setSelectedTube(event.target.value)}
                        label={t('Tube')}
                        required
                      >
                        {tubes.map((tube) => (
                          <MenuItem key={tube.id} value={tube.id}>
                            {tube.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t('Description')}
                      value={description}
                      onChange={(event) => setDescription(event.target.value)}
                      style={{ marginRight: '10px' }}
                      fullWidth
                      multiline
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t('Result')}
                      value={result}
                      onChange={(event) => setResult(event.target.value)}
                      style={{ marginRight: '10px' }}
                      type="number"
                      fullWidth
                      required
                    />
                  </Grid>
                  
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t('Unit')}
                      value={unit}
                      onChange={(event) => setUnit(event.target.value)}
                      style={{ marginRight: '10px' }}
                      fullWidth
                      multiline
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t('Standard from')}
                      value={standardFrom}
                      onChange={(event) => setStandardFrom(event.target.value)}
                      style={{ marginRight: '10px' }}
                      fullWidth
                      type="number"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t('Standard to')}
                      value={standardTo}
                      onChange={(event) => setStandardTo(event.target.value)}
                      style={{ marginRight: '10px' }}
                      fullWidth
                      type="number"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t('Cost')}
                      value={cost}
                      onChange={(event) => setCost(event.target.value)}
                      style={{ marginRight: '10px' }}
                      fullWidth
                      type="number"
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-end">
                      <Button
                        type="submit"
                        variant="contained"
                        disabled={status === 'completed'}
                        color="primary"
                        style={{ minWidth: 'auto' }}
                      >
                        {t('Submit')}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
                <div></div>
                <Snackbar open={open} autoHideDuration={2000} onClose={handleCloseAlert}>
                  <Alert severity="success">{t('Updated!')}</Alert>
                </Snackbar>
              </Container>
            </form>
          </AccordionDetails>
        </Accordion>
        </Container>
  
            <Container maxWidth="md">
              <div>
                {fetchError && <p>{fetchError}</p>}
                {tests.length > 0 && (
                  <div>
                    <p> </p>
                    <Grid container spacing={2}>
                      {tests
                        .sort((a, b) => b.id - a.id)
                        .map((testItem) => (
                          <Grid item xs={12} sm={6} md={12} lg={12} key={testItem.id}>
                            <Card>
                              <CardContent>
                                <div onClick={(event) => handleEditClick(event, testItem)}style={{ cursor: 'pointer' }}> 
                                <Divider textAlign="right">{t('ID')} {testItem.id} </Divider>
                                <Grid container alignItems="center" >
                                <BiotechIcon style={{ color: 'green', marginRight: '10px', fontSize: 'large' }} />
                                  <Typography variant="h6">{testItem.name}</Typography>
                                </Grid>
                                <Typography variant="body1">{t('Tube')}: {testItem.tubes?.name}</Typography>
                                <Typography variant="body2" color='green'>{t('Standard from')}: {testItem.standardFrom}</Typography>
                                <Typography variant="body2" color='red'>{t('Standard to')}: {testItem.standardTo}</Typography>
                                <Typography variant="body2">{t('Result')}: {testItem.result}</Typography>
                                <Typography variant="body2">{t('Cost')}: {testItem.cost}</Typography>
                                </div>
                              </CardContent>
                              <CardActions style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <Button
                            color="primary"
                            onClick={(event) => handleEditClick(event, testItem)}
                          >
                            {t('Edit')}
                          </Button>
                                <Button
                                  color="error"
                                  disabled={status === 'completed'}
                                  onClick={(event) => {
                                    DeleteTest(event, testItem);
                                  }}
                                >
                                  {t('Delete')}
                                </Button>
                              </CardActions>
                            </Card>
                          </Grid>
                        ))}
                    </Grid>
                  </div>
                )}
              </div>
            </Container>
  


            {/* Edit Dialog */}
            <Dialog open={openEditDialog} onClose={handleCloseEditDialog}>
        <DialogTitle>{t('Edit test')}</DialogTitle>
        <DialogContent>
        <p></p>
        <Grid container spacing={2}>
                  
        <Grid item xs={12} sm={6}>
          <TextField
            label={t('Name')}
            value={name}
            onChange={(event) => setName(event.target.value)}
            style={{ marginBottom: '10px' }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label={t('Description')}
            value={description}
            onChange={(event) => setDescription(event.target.value)}
            style={{ marginBottom: '10px' }}
            fullWidth
            multiline
          />
         </Grid>
         <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="edit-tube-select-label">{t('Tube')}</InputLabel>
              <Select
                labelId="edit-tube-select-label"
                id="edit-tube-select"
                value={selectedTubeInEdit}
                onChange={(event) => setSelectedTubeInEdit(event.target.value)}
                label={t('Tube')}
              >
                {tubes.map((tube) => (
                  <MenuItem key={tube.id} value={tube.id}>
                    {tube.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

         <Grid item xs={12} sm={6}>
          <TextField
            label={t('Result')}
            value={result}
            onChange={(event) => setResult(event.target.value)}
            style={{ marginBottom: '10px' }}
            fullWidth
            type="number"
          />
         </Grid>
         <Grid item xs={12} sm={6}>
          <TextField
            label={t('Unit')}
            value={unit}
            onChange={(event) => setUnit(event.target.value)}
            style={{ marginBottom: '10px' }}
            fullWidth
            multiline
            disabled
          />
         </Grid>
         <Grid item xs={12} sm={6}>
          <TextField
            label={t('Standard from')}
            value={standardFrom}
            onChange={(event) => setStandardFrom(event.target.value)}
            style={{ marginBottom: '10px' }}
            fullWidth
            type="number"
            disabled
          />
         </Grid>
         <Grid item xs={12} sm={6}>
          <TextField
            label={t('Standard to')}
            value={standardTo}
            onChange={(event) => setStandardTo(event.target.value)}
            style={{ marginBottom: '10px' }}
            fullWidth
            type="number"
            disabled
          />
         </Grid>
         <Grid item xs={12} sm={6}>
          <TextField
            label={t('Cost')}
            value={cost}
            onChange={(event) => setCost(event.target.value)}
            style={{ marginBottom: '10px' }}
            fullWidth
            type="number"
          />
         </Grid>
                  </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditDialog} color="primary">
            {t('Cancel')}
          </Button>
          <Button onClick={handleSaveEdit} color="primary">
            {t('Save')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ManagerTestsOrder;
