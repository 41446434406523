import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography,Container,Box,Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from "react-i18next";
import ResultOfTests from '../../Printouts/ResultOfTests';
import OrderConfirmation from '../../Printouts/OrderConfirmation';
import TubesPrintouts from '../../Printouts/TubesPrintouts';
import { PDFViewer,PDFDownloadLink } from '@react-pdf/renderer';
import { useParams } from 'react-router-dom';

const ManagerPrintouts = () => {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  return (
    <div>
      <Container maxWidth="md">
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" fontWeight="bold">{t('Order confirmation')}</Typography>
        </AccordionSummary>
        <AccordionDetails >
        <PDFDownloadLink document={<OrderConfirmation id={id} />} fileName="order.pdf">
      {({ blob, url, loading, error }) =>
        loading ? 'Loading document...' : t('Download')
      }
        </PDFDownloadLink>
          <Typography></Typography>
          <Box width="100%" height='85vh'>
              <PDFViewer width="100%" height="100%">
                <OrderConfirmation id={id} />
              </PDFViewer>
            </Box>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" fontWeight="bold">{t('Test results')}</Typography>
        </AccordionSummary>
        <AccordionDetails >
        <PDFDownloadLink document={<ResultOfTests id={id} />} fileName="result.pdf">
      {({ blob, url, loading, error }) =>
        loading ? 'Loading document...' : t('Download')
      }
        </PDFDownloadLink>
          <Typography></Typography>
          <Box width="100%" height='85vh'>
              <PDFViewer width="100%" height="100%">
                <ResultOfTests id={id} />
              </PDFViewer>
            </Box>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" fontWeight="bold">{t('Tubes')}</Typography>
        </AccordionSummary>
        <AccordionDetails >
        <PDFDownloadLink document={<TubesPrintouts id={id} />} fileName="result.pdf">
      {({ blob, url, loading, error }) =>
        loading ? 'Loading document...' : t('Download')
      }
        </PDFDownloadLink>
          <Typography></Typography>
          <Box width="100%" height='85vh'>
              <PDFViewer width="100%" height="100%">
                <TubesPrintouts id={id} />
              </PDFViewer>
            </Box>
        </AccordionDetails>
      </Accordion>
      </Container>

  </div>
  );
};

export default ManagerPrintouts;
