import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import supabase from '../../supabaseClient';
import moment from 'moment';
import { Font } from '@react-pdf/renderer';
Font.register({
  family: 'OpenSans',
  src: 'https://fonts.gstatic.com/s/opensans/v18/mem8YaGs126MiZpBA-U1UpcaXcl0Aw.ttf',
});

const TubesPrintouts = ({ id }) => {
  const { t, i18n } = useTranslation();
  const [userID, setUserID] = useState('');
  const [idConfig, setIdConfiguration] = useState('');
  const [fetchError, setFetchError] = useState(null);
  const [order, setOrder] = useState([]);
  const [tubes, setTubes] = useState([]);
  const [currentDate, setCurrentDate] = useState('');

  const getCurrentDate = () => {
    const current = moment().format('DD:MM:YYYY');
    setCurrentDate(current);
  };

  useEffect(() => {
    const checkSession = async () => {
      const { data } = await supabase.auth.getSession();
      if (data.session) {
        setUserID(data.session.user.id);
        fetchData(data.session.user.id);
      }
    };
    checkSession();
  }, []);

  const fetchData = async (userId) => {
    const { data: profileData, error: profileError } = await supabase
      .from('profiles')
      .select('id_configuration')
      .eq('id', userId)
      .single();

    if (profileError) {
      console.log(profileError);
    } else if (profileData) {
      setIdConfiguration(profileData.id_configuration);
    }
  };

  const handleFetchData = async (id) => {
    const { data, error } = await supabase
      .from('orders')
      .select(`*,
        contractors (
            name,address,phone_number,email
        ),profiles (username)
      `)
      .eq('id', id)
      .eq('id_configuration', idConfig)
      .single();
    if (data) {
      setOrder(data);
    }
  };

  const fetchTubes = async (idConfiguration, id) => {
    const { data, error } = await supabase
      .from('tubes')
      .select()
      .eq('id_order', id)
      .eq('id_configuration', idConfiguration)
      .is('isDeleted', null);
    if (error) {
      console.error(error);
      setTubes([]);
      setFetchError(t('No tubes'));
    }
    if (data) {
      setTubes(data);
      setFetchError(null);
    }
  };

  useEffect(() => {
    if (idConfig) {
      handleFetchData(id);
      fetchTubes(idConfig, id);
      getCurrentDate();
    }
  }, [idConfig]);

  const renderLabelPage = (label) => (
    <Page style={styles.page} key={label.id}>
      <View style={styles.section}>
        <Text style={styles.text}>{t('Order')}: {order.id}</Text>
        {/* <Text style={styles.text}>{t('Print date')}: {currentDate}</Text> */}
        <View>
          <Text style={styles.text}>{t('Tube')}: {label.id}</Text>
          <Text style={styles.text}>{label.name}</Text>
          <Text style={styles.text}>{label.date} </Text>
          {/* Dodaj więcej informacji dotyczących etykiety, jeśli to konieczne */}
        </View>
      </View>
    </Page>
  );

  return (
    <Document>
      {/* Iteruj przez każdą etykietę i generuj osobną stronę */}
      {tubes.map((label) => renderLabelPage(label))}
    </Document>
  );
};

const styles = StyleSheet.create({
    page: {
      fontFamily: 'OpenSans',
        width: 50,  // zmień szerokość na 50mm
        height: 25, // zmień wysokość na 25mm
        flexDirection: 'row',
        backgroundColor: 'white',
        orientation: 'landscape', // dodaj orientację poziomą
      },
  section: {
    margin: 5,
    padding: 5,
    flexGrow: 1,
    borderBottom: '1px solid #ccc',
  },
  text: {
    fontFamily: 'OpenSans',
    fontSize: 5, // zmniejsz rozmiar czcionki
    marginBottom: 2, // zmniejsz margines dolny
    marginLeft: 2,  // dostosuj margines lewy
    marginRight: 2, // dostosuj margines prawy
  },
});


export default TubesPrintouts;
