import { BrowserRouter, Routes, Route} from "react-router-dom"

// pages

import Login from "../Pages/Common/Login";
import Home from "../Pages/Common/Home";
import NoAccessLicence from "../Pages/Common/NoAccessLicence";
import WaitingRoomForNewUser from "../Pages/Common/WaitingRoomForNewUser";
import ManagerAdministraction from "../Pages/Manager/ManagerAdministraction";
import ManagerProfile from "../Pages/Manager/ManagerProfile";
import ManagerOrders from "../Pages/Manager/ManagerOrders";
import ManagerSchedule from "../Pages/Manager/ManagerSchedule";
import ManagerReports from "../Pages/Manager/ManagerReports";
import ManagerUserDetails from "../Pages/Manager/ManagerUserDetails";
import ManagerContractor from "../Pages/Manager/ManagerContractor";
import ManagerOrderDetails from "../Pages/Manager/ManagerOrderDetails";
import ManagerAddNewOrder from "../Pages/Manager/ManagerAddNewOrder";
import ManagerContractorDetails from "../Pages/Manager/ManagerContractorDetails";
import ManagerAddNewContractor from "../Pages/Manager/ManagerAddNewContractor";
import Info from "../Pages/Common/Info/Info";
import InfoContact from "../Pages/Common/Info/InfoContact";
import TermsAndConditions from "../Pages/Common/TermsAndConditions";
import FAQ from "../Pages/Common/Info/FAQ";
import Accreditation from "../Pages/Common/Info/Accreditation";
import ManagerDevices from "../Pages/Manager/ManagerDevices";
import ManagerDeviceDetails from "../Pages/Manager/ManagerDeviceDetails";
import ManagerAddNewDevice from "../Pages/Manager/ManagerAddNewDevice";
import WorkerSchedule from "../Pages/Worker/WorkerSchedule";
import WorkerReports from "../Pages/Worker/WorkerReports";
import WorkerOrders from "../Pages/Worker/WorkerOrders";
import WorkerOrderDetails from "../Pages/Worker/WorkerOrderDetails";
import WorkerProfile from "../Pages/Worker/WorkerProfile";
import WorkerAddNewOrder from "../Pages/Worker/WorkerAddNewOrder";

function Routing() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/Home" element={<Home />} />
        <Route path="/NoAccessLicence" element={<NoAccessLicence />} />
        <Route path="/WaitingRoomForNewUser" element={<WaitingRoomForNewUser />} />
        <Route path="/Administration" element={<ManagerAdministraction />} />
        <Route path="/Schedule" element={<ManagerSchedule />} />
        <Route path="/Reports" element={<ManagerReports />} />
        <Route path="/Contractors" element={<ManagerContractor />} />
        <Route path="/Devices" element={<ManagerDevices />} />
        <Route path="/Orders" element={<ManagerOrders />} />
        <Route path="/Profile" element={<ManagerProfile />} />
        <Route path="/ManagerUserDetails/:id" element={<ManagerUserDetails />} />
        <Route path="/ManagerOrderDetails/:id" element={<ManagerOrderDetails />} />
        <Route path="/Contractor/:id" element={<ManagerContractorDetails />} />
        <Route path="/ManagerDeviceDetails/:id" element={<ManagerDeviceDetails />} />
        <Route path="/ManagerAddNewOrder" element={<ManagerAddNewOrder />} />
        <Route path="/AddNewDevice" element={<ManagerAddNewDevice />} />
        <Route path="/AddNewContractor" element={<ManagerAddNewContractor />} />
{/* website */}
        <Route path="/Info" element={<Info />} />
        <Route path="/infoContact" element={<InfoContact />} />
        <Route path="/termsAndConditions" element={<TermsAndConditions />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/Accreditation" element={<Accreditation />} />
{/* worker */}
        <Route path="/WorkerSchedule" element={<WorkerSchedule />} />
        <Route path="/WorkerReports" element={<WorkerReports />} />
        <Route path="/WorkerOrders" element={<WorkerOrders />} />
        <Route path="/WorkerAddNewOrder" element={<WorkerAddNewOrder />} />
        <Route path="/WorkerOrderDetails/:id" element={<WorkerOrderDetails />} />
        <Route path="/WorkerProfile" element={<WorkerProfile />} />

      </Routes>
    </BrowserRouter>
  );
}

export default Routing;
