// theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      light: '#A5D6A7',   // Jasny odcień zieleni
      main: '#4CAF50',    // Główny kolor (zielony)
      dark: '#388E3C',    // Ciemniejszy odcień zieleni
      contrastText: '#fff', // Kontrastowy tekst
    },
    secondary: {
      light: '#FF9800',  // Jasny pomarańcz
      main: '#F57C00',   // Kolor podstawowy (pomarańcz)
      dark: '#E65100',   // Ciemniejszy pomarańcz
      contrastText: '#000', // Kontrastowy tekst
    },
  },
});

export default theme;
