
import Routing from './Config/Routing';

function App() {
  return (
    <Routing></Routing>
  );
}

export default App;
