import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box, Container, Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import InfoNavBar from '../../../Components/NavigationBar/InfoNavBar';
import Footer from '../../../Components/Info/Footer';
function Accreditation() {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <InfoNavBar></InfoNavBar>
      <p></p>
      <div style={{ background: 'white', minHeight: '85vh' }}>
        <p></p>
        <Container maxWidth="md" style={{ marginTop: '8rem' }}>

          <p></p>

    <h1 style={{ color:"green"}}>Akredytacja Laboratorium</h1>
    <p>Akredytacja laboratorium to proces, przez który <strong>niezależna instytucja</strong> ocenia, czy laboratorium spełnia ustalone standardy operacyjne i jakościowe. Jest to nie tylko dowód na wysokie kompetencje i wiarygodność, ale także otwiera drzwi do nowych możliwości biznesowych i naukowych. Jednakże, aby sprostać rygorystycznym wymaganiom akredytacji, laboratoria muszą wykazać się doskonałością w zarządzaniu danymi, dokumentacją oraz procedurami badawczymi.</p>
    
    <h2 style={{color:"green"}}>System Informatyczny w Laboratorium</h2>
    <p>Tutaj kluczową rolę odgrywa <strong>system informatyczny</strong>. Współczesne oprogramowanie dla laboratoriów umożliwia nie tylko efektywne zarządzanie danymi, ale również ich agregację, analizę i bezpieczne przechowywanie. Systemy te umożliwiają szybką wymianę informacji między zespołami badawczymi, ułatwiają przestrzeganie protokołów oraz zwiększają ogólną efektywność pracy.</p>

    <h2 style={{ color:"green"}}>Bezpieczeństwo Informacji</h2>
    <p>Ponadto, w dobie cyfryzacji, zapewnienie <strong>bezpieczeństwa informacji</strong> stało się kluczowe. Oprogramowanie dla laboratoriów zapewnia zabezpieczenia przeciwko nieautoryzowanemu dostępowi, co jest niezwykle ważne w kontekście wrażliwych danych badawczych. Akredytujące organy coraz częściej zwracają uwagę na aspekt cyfrowego bezpieczeństwa, co czyni system informatyczny nie tylko narzędziem efektywności, ale również elementem zwiększającym wiarygodność laboratorium.</p>

    <h2 style={{ color:"green" }}>Strategiczna Rola Oprogramowania Informatycznego</h2>
    <p>Wnioskując, dla laboratoriów dążących do uzyskania akredytacji, inwestycja w zaawansowane oprogramowanie informatyczne jest nie tylko kwestią udogodnienia. To <strong>strategiczna decyzja</strong>, która wpływa na zdolność laboratorium do spełnienia wymogów akredytacyjnych, zapewnienia bezpieczeństwa danych i utrzymania wysokiej jakości pracy badawczej. W obliczu tych wyzwań, nowoczesne oprogramowanie dla laboratoriów staje się niezbędnym elementem, który umożliwia laboratoriom nie tylko przetrwanie, ale i rozwój w dynamicznie zmieniającym się świecie nauki i technologii.</p>
      </Container>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default Accreditation;
