import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import supabase from '../../supabaseClient';
import moment from 'moment';
import { Font } from '@react-pdf/renderer';
Font.register({
  family: 'OpenSans',
  src: 'https://fonts.gstatic.com/s/opensans/v18/mem8YaGs126MiZpBA-U1UpcaXcl0Aw.ttf',
});


const OrderConfirmation = ({ id }) => {
  const { t, i18n } = useTranslation();
  const [userID, setUserID] = useState('');
  const [idConfig, setIdConfiguration] = useState('');
  const [fetchError, setFetchError] = useState(null);
  const [order, setOrder] = useState([]);
  const [tests, setTests] = useState([]);
  const [tubes, setTubes] = useState([]);
  const [currentDate, setCurrentDate] = useState('');
  

  const getCurrentDate = () => {
    const current = moment().format('DD:MM:YYYY');
    setCurrentDate(current);

  };

  useEffect(() => {
    const checkSession = async () => {
      const { data } = await supabase.auth.getSession();
      if (data.session) {
        setUserID(data.session.user.id);
        fetchData(data.session.user.id);
      }
    };
    checkSession();
  }, []);

  const fetchData = async (userId) => {
    const { data: profileData, error: profileError } = await supabase
      .from('profiles')
      .select('id_configuration')
      .eq('id', userId)
      .single();

    if (profileError) {
      console.log(profileError);
    } else if (profileData) {
      setIdConfiguration(profileData.id_configuration);
    }
  };


  const handleFetchData = async (id) => {
    const { data, error } = await supabase
      .from('orders')
      .select(`*,
      contractors (
          name,address,phone_number,email
      ),profiles (username)
  `)
      .eq('id',id)
      .eq('id_configuration', idConfig)
      .single();
    if (data) {
setOrder(data);
    }
  };

  const fetchTests = async (idConfiguration, id) => {
    const { data, error } = await supabase
      .from('tests')
      .select()
      .eq('id_order', id)
      .eq('id_configuration', idConfiguration);
    if (error) {
      console.error(error);
      setTests([]);
      setFetchError(t('No Tests'));
    }
    if (data) {
      setTests(data);
      setFetchError(null);
    }
  };


  const fetchTubes = async (idConfiguration, id) => {
    const { data, error } = await supabase
      .from('tubes')
      .select()
      .eq('id_order', id)
      .eq('id_configuration', idConfiguration)
      .is('isDeleted', null);
    if (error) {
      console.error(error);
      setTubes([]);
      setFetchError(t('No tubes'));
    }
    if (data) {
      setTubes(data);
      setFetchError(null);
    }
  };

  useEffect(() => {
    if (idConfig) {
      handleFetchData(id);
      fetchTests(idConfig, id);
      fetchTubes(idConfig, id);
      getCurrentDate();
    }
  }, [idConfig]);

  const styles = StyleSheet.create({
    page: {
      fontFamily: 'OpenSans',
      padding: 30,
      fontSize: 10,
      lineHeight: 1.5,
      color: '#333',
    },
    header: {
      fontSize: 22,
      marginBottom: 20,
      textAlign: 'center',
      color: '#444',
      fontWeight: 'bold',
    },
    subHeader: {
      fontSize: 16,
      marginVertical: 10,
      color: '#666',
      fontWeight: 'bold',
    },
    section: {
      margin: 10,
      padding: 10,
    },
    text: {
      marginBottom: 4,
      paddingLeft: 10,
    },
    list: {
      paddingLeft: 15,
    },
    listItem: {
      marginBottom: 2,
    },
  });

  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.header}>{t('Order confirmation')}</Text>
          {/* Informacje o zleceniu */}
          <Text style={styles.subHeader}>{t('Order Information')}</Text>
          <Text style={styles.text}>{t('ID')}: {order.id}</Text>
          <Text style={styles.text}>{t('Print date')}: {currentDate}</Text>
          <Text style={styles.text}>{t('Description')}: {order.description}</Text>

          {/* Informacje o zleceniodawcy */}
          <Text style={styles.subHeader}>{t('Contractor')}</Text>
          <Text style={styles.text}>{t('Name')}: {order.contractors?.name}</Text>
          <Text style={styles.text}>{t('Address')}: {order.contractors?.address}</Text>
          <Text style={styles.text}>{t('Phone')}: {order.contractors?.phone_number}</Text>
          <Text style={styles.text}>{t('Email')}: {order.contractors?.email}</Text>

          {/* Lista testów */}
          <Text style={styles.subHeader}>{t('Tests')}</Text>
          {tests.sort((a, b) => b.id - a.id).map((testItem) => (
            <Text key={testItem.id} style={styles.listItem}>
              &bull; {testItem.name} - {testItem.description}
            </Text>
          ))}

          {/* Lista próbek */}
          <Text style={styles.subHeader}>{t('Tubes')}</Text>
          {tubes.sort((a, b) => b.id - a.id).map((tubeItem) => (
            <Text key={tubeItem.id} style={styles.listItem}>
              &bull; {tubeItem.name} - {tubeItem.date}
            </Text>
          ))}
        </View>
      </Page>
    </Document>
  );
};

export default OrderConfirmation;
