import React from 'react';
import { Button, Card, CardContent, Container, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'; 

const MainFunction = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  return (
<div>
<div style={{ background: 'white', minHeight: '70vh' }}>

      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Typography variant="h4" align="center" 
                      sx={{
                        mr: 2,
                        mt: 10,
                        // fontFamily: 'lato',
                        fontWeight: 700,
                        textDecoration: 'none',
                        color:"green"
                      }}
                    >
              {t("Manage tests and tubes")}
            </Typography>
<p></p>
                  <Typography variant="h9" align='left' sx={{  marginBottom: 2 }}>
                    {t("This feature streamlines the process of handling laboratory samples and tests. It allows lab technicians to effortlessly add new samples, track their status, and associate them with specific tests. This functionality is essential for maintaining accurate records and ensuring that each sample is correctly processed and analyzed, contributing to the lab's efficiency and reliability.")}
                  </Typography>
                
          </Grid>
          <Grid item xs={12} md={8} >
            <div style={{ padding: '20px' }}>
            <img
               src="/zlecenie.gif"
              alt="Your Business"
              style={{ width: '100%', height: 'auto' }}
            />
            </div>
          </Grid>



                    <Grid item xs={12} md={4}>
            <Typography variant="h4" align="center" 
                      sx={{
                        mr: 2,
                        mt: 10,
                        // fontFamily: 'lato',
                        fontWeight: 700,
                        textDecoration: 'none',
                        color:"green"
                      }}
                    >
              {t("Plan your orders")}
            </Typography>
<p></p>
                  <Typography variant="h9" align='left' sx={{  marginBottom: 2 }}>
                    {t("The 'Plan Your Orders' function is designed to optimize the workflow of laboratory orders. It enables users to create, schedule, and manage orders for various tests and analyses. This tool not only simplifies the ordering process but also helps in prioritizing tasks and organizing the workload, ensuring that the laboratory operates smoothly and meets its deadlines efficiently.")}
                  </Typography>
                
          </Grid>
          <Grid item xs={12} md={8} >
            <div style={{ padding: '20px' }}>
            <img
               src="/harmonogram.gif"
              alt="Your Business"
              style={{ width: '100%', height: 'auto' }}
            />
            </div>
          </Grid>

          <Grid item xs={12} md={4}>
            <Typography variant="h4" align="center" 
                      sx={{
                        mr: 2,
                        mt: 10,
                        // fontFamily: 'lato',
                        fontWeight: 700,
                        textDecoration: 'none',
                        color:"green"
                      }}
                    >
              {t("Generate reports and collect data")}
            </Typography>
<p></p>
                  <Typography variant="h9" align='left' sx={{  marginBottom: 2 }}>
                    {t("This component of the system is dedicated to generating comprehensive reports and collecting detailed data from conducted tests. This feature is vital for analyzing test results, supporting decision-making, and maintaining a high standard of record-keeping, which is crucial in any laboratory setting.")}
                  </Typography>
                
          </Grid>
          <Grid item xs={12} md={8} >
            <div style={{ padding: '20px' }}>
            <img
               src="/raporty.gif"
              alt="Your Business"
              style={{ width: '100%', height: 'auto' }}
            />
            </div>
            </Grid>

        </Grid>
      </Container>
    </div>
</div>
  );
};

export default MainFunction;
