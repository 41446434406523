import React, { useState, useEffect } from 'react';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import supabase from '../../../supabaseClient';
import { useParams } from "react-router-dom";
import BuildIcon from '@mui/icons-material/Build';
import { useTranslation } from "react-i18next";
import moment from 'moment';

const EndingMaintenanceDevice = () => {
  const [events, setEvents] = useState([]);
  const {id} = useParams();
  const { t } = useTranslation();
  const [userID, setUserID] = useState('');
  const [idConfig, setIdConfiguration] = useState('');


  useEffect(() => {
    const checkSession = async () => {
        const { data } = await supabase.auth.getSession();
        if (data.session) {
            setUserID(data.session.user.id);
            fetchData(data.session.user.id);
        }
    };
    checkSession();
}, []);

const fetchData = async (userId) => {
    const { data: profileData, error: profileError } = await supabase
        .from('profiles')
        .select('id_configuration')
        .eq('id', userId)
        .single();
    if (profileError) {
        console.log(profileError);
    } else if (profileData) {
        setIdConfiguration(profileData.id_configuration);
    }
}

  useEffect(() => {
    const fetchMaintenance = async (idConfig) => {
      // Pobierz dane z tabeli 'maintenance'
      let { data: maintenances, error: maintenancesError } = await supabase
        .from('maintenance')
        .select(`*,
        devices (
            name
        )
    `)
    .eq('id_configuration', idConfig).is('isDeleted', null);

      if (maintenancesError) {
        console.error(maintenancesError);
        return;
      }

      // Filtracja i sortowanie danych
      const filteredEvents = maintenances
        .filter(maintenance => moment(maintenance.nextScheduledDate, "DD/MM/YYYY HH:mm").isAfter(moment()))
        .sort((a, b) => moment(a.nextScheduledDate, "DD/MM/YYYY HH:mm").diff(moment(b.nextScheduledDate, "DD/MM/YYYY HH:mm")))
        .map(maintenance => ({
          ...maintenance,
          label: t("Maintenance"),
          icon: <BuildIcon/>
        }));

      setEvents(filteredEvents);
    };

    fetchMaintenance(idConfig);
  }, [id, t,idConfig]);

  return (
    <VerticalTimeline>
      {events.map((event, index) => (
        <VerticalTimelineElement
          key={index}
          date={event.nextScheduledDate}
          iconStyle={{ background: '#4CAF50', color: '#fff' }}
          icon={<i className="material-icons">{event.icon}</i>}
        >
          <h3 className="vertical-timeline-element-title">{event.devices?.name}</h3>
          <p>{event.name}</p>
        </VerticalTimelineElement>
      ))}
    </VerticalTimeline>
  );
};

export default EndingMaintenanceDevice;
