import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Grid,
  Container,
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import supabase from '../../../supabaseClient';
import { useParams } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useTranslation } from 'react-i18next';

function Maintenance() {
  const { t, i18n } = useTranslation();
  const [name, setName] = useState('');
  const [remarks, setRemarks] = useState('');
  const [fetchError, setFetchError] = useState(null);
  const [maintenances, setMaintenance] = useState(null);

  const [userID, setUserID] = useState('');
  const [idConfig, setIdConfiguration] = useState('');

  // Dialog state
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedMaintenance, setSelectedMaintenance] = useState(null);

  useEffect(() => {
    const checkSession = async () => {
      const { data } = await supabase.auth.getSession();
      if (data.session) {
        setUserID(data.session.user.id);
        fetchData(data.session.user.id);
      }
    };
    checkSession();
  }, []);

  const fetchData = async (userId) => {
    const { data: profileData, error: profileError } = await supabase
      .from('profiles')
      .select('id_configuration')
      .eq('id', userId)
      .single();
    if (profileError) {
      console.log(profileError);
    } else if (profileData) {
      setIdConfiguration(profileData.id_configuration);
    }
  };

  useEffect(() => {
    if (idConfig) {
        fetchMaintenance(idConfig);
    }
  }, [idConfig]);

  const handleSubmit = (event) => {
    event.preventDefault();
    insertMaintenance();
    setName('');
    setRemarks('');
  };

  const insertMaintenance = async () => {
    const { data, error } = await supabase
      .from('typeOfMaintenance')
      .insert([{ id_configuration: idConfig, name: name, remarks: remarks }]);
    handleClickAlert();
    fetchMaintenance(idConfig);
    if (error) {
      console.log(error);
    }
    if (data) {
    }
  };
  const handleDeleteClick = async (test) => {
    await supabase
      .from('typeOfMaintenance')
      .update({ isDeleted: 1 })
      .eq('id', test.id);

      fetchMaintenance(idConfig);

    handleClickAlert();
  };

  const fetchMaintenance = async (idConfig) => {
    const { data, error } = await supabase
      .from('typeOfMaintenance')
      .select()
      .eq('id_configuration', idConfig)
      .is('isDeleted', null); 
    if (error) {
      console.log(error);
      setMaintenance(null);
      setFetchError(t('No products'));
    }
    if (data) {
        setMaintenance(data);
      setFetchError(null);
    }
  };

  const [open, setOpen] = useState(null);

  const handleClickAlert = () => {
    setOpen(true);
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleEditClick = (maintenance) => {
    setSelectedMaintenance(maintenance);
    setName(maintenance.name);
    setRemarks(maintenance.remarks);
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  const handleSaveEdit = async () => {
    await supabase
      .from('typeOfMaintenance')
      .update({ name, remarks })
      .eq('id', selectedMaintenance.id);

      fetchMaintenance(idConfig);

    setOpenEditDialog(false);

    handleClickAlert();
  };

  return (
    <div>
      <div>
        <p></p>
        <Container maxWidth="md">
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6" fontWeight="bold">
                {t('Add')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography></Typography>
              <form
                onSubmit={handleSubmit}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Container maxWidth="md">
                  <Typography variant="h4" align="center" gutterBottom>
                    <p></p>
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label={t('Name')}
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                        style={{ marginRight: '10px' }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label={t('Remarks')}
                        value={remarks}
                        onChange={(event) => setRemarks(event.target.value)}
                        style={{ marginRight: '10px' }}
                        fullWidth
                        multiline
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Box display="flex" justifyContent="flex-end">
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          style={{ minWidth: 'auto' }}
                        >
                          {t('Submit')}
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                  <div></div>
                  <Snackbar
                    open={open}
                    autoHideDuration={2000}
                    onClose={handleCloseAlert}
                  >
                    <Alert severity="success"> {t('Updated!')}</Alert>
                  </Snackbar>
                </Container>
              </form>
            </AccordionDetails>
          </Accordion>

          <div>
            <p></p>
            <Grid container spacing={3}>
              {maintenances &&
                maintenances.sort((a, b) => b.id - a.id).map((item) => {
                  return (
                    <Grid item xs={12} sm={6} md={6} lg={4} key={item.id}>
                      <Card>
                        <CardContent>
                        <div onClick={() => handleEditClick(item)} style={{ cursor: 'pointer' }}>
                          <Typography variant="h6">{item.name}</Typography>
                          <Typography>
                            {t('Remarks')}: {item.remarks}
                          </Typography>
                            </div>
                            <p></p>
                          <Box display="inline-block" marginRight={1}>
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => handleEditClick(item)}
                          >
                            {t('Edit')}
                          </Button>
                          </Box>
                          <Box display="inline-block" marginRight={1}>
                          <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() => handleDeleteClick(item)}
                          >
                            {t('Delete')}
                          </Button>
                          </Box>
   
                        </CardContent>
                      </Card>
                    </Grid>
                  );
                })}
            </Grid>
          </div>
        </Container>
      </div>

      {/* Edit Dialog */}
      <Dialog open={openEditDialog} onClose={handleCloseEditDialog}>
        <DialogTitle>{t('Edit')}</DialogTitle>
        <DialogContent>
        <p></p>
          <TextField
            label={t('Name')}
            value={name}
            onChange={(event) => setName(event.target.value)}
            style={{ marginBottom: '10px' }}
            fullWidth
          />
          <TextField
            label={t('Remarks')}
            value={remarks}
            onChange={(event) => setRemarks(event.target.value)}
            style={{ marginBottom: '10px' }}
            fullWidth
            multiline
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditDialog} color="primary">
            {t('Cancel')}
          </Button>
          <Button onClick={handleSaveEdit} color="primary">
            {t('Save')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Maintenance;
