import React, { useEffect, useState } from 'react';
import { DataGrid,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import supabase from '../../supabaseClient';
import { useTranslation } from "react-i18next";
import { Button, } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

const ManagerAllTubesReport = () => {
    const navigate = useNavigate();
    const [userID, setUserID] = useState('');
    const [idConfig, setIdConfiguration] = useState('');
  const [services, setServices] = useState([]);
  const { t, i18n } = useTranslation();


  useEffect(() => {
    const checkSession = async () => {
      const { data } = await supabase.auth.getSession();
      if (data.session) {
        setUserID(data.session.user.id);
        fetchData(data.session.user.id);
      }
    };
    checkSession();
  }, []);
  
  const fetchData = async (userId) => {
    const { data: profileData, error: profileError } = await supabase
        .from('profiles')
        .select('id_configuration')
        .eq('id', userId)
        .single();
    if (profileError) {
        console.log(profileError);
    } else if (profileData) {
        setIdConfiguration(profileData.id_configuration);
    }
}
  
  useEffect(() => {
    const fetchData = async (idConfiguration) => {
      // Pobieranie danych z tabeli 'services' i łączenie z 'tasks' oraz 'contractor'
      let { data: servicesData, error } = await supabase
        .from('tubes')
        .select().eq('id_configuration', idConfiguration).is('isDeleted', null);

      if (error) console.log("error", error);
      else {
        setServices(servicesData);
      }
    };
    if (idConfig) {
        fetchData(idConfig)
    }
  }, [idConfig]);

  const ForwardToTask = async(event, cellValues)=>{
    navigate('/ManagerOrderDetails/' + cellValues.row.id_order);
  }

  // Definiowanie kolumn dla DataGrid
  const columns = [
    { field: 'id', headerName: t("ID"), width: 60 },
    { field: 'id_order', headerName: t("Order"), width: 80 },
    { field: 'name', headerName: t("Name"), width: 250 },
    { field: 'description', headerName: t("Description"), width: 350},
    { field: 'status', headerName: t("Status"), width: 100 , valueGetter: (params) => {
        const taskStatus = params.value;
        if (taskStatus === 'open') {
            return t('Open');
        } else if (taskStatus === 'inProgress') {
            return t('In progress');
        } else if (taskStatus === 'completed') {
            return t('Completed');
        } 
        else if (taskStatus === 'cancelled') {
            return t('Cancelled');
        }else {
            return taskStatus;
        }
    }, },
    { field: 'date', headerName: t("Date"), width: 140 },
    {
        field: "Action",headerName: t("Action"), width: 100 ,
        renderCell: (cellValues) => {
          return ( 
            <Button
            color="primary"
            onClick={(event) => {
                ForwardToTask(event, cellValues);
            }}
            >{t("details")}</Button>
          );
        }
      },

    // Możesz dodać więcej kolumn zgodnie z potrzebami
  ];

  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={services}
        columns={columns}
        pageSize={12}
        slots={{
            toolbar: CustomToolbar,
          }}
      />
    </div>
  );
};

export default ManagerAllTubesReport;
