import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box, Container, Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import InfoNavBar from '../../../Components/NavigationBar/InfoNavBar';
import FaqComponent from '../../../Components/Info/FaqComponent';
import Footer from '../../../Components/Info/Footer';
function FAQ() {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <InfoNavBar></InfoNavBar>
      <p></p>
      <div style={{ background: 'white', minHeight: '85vh' }}>
        <p></p>
        <Container maxWidth="md" style={{ marginTop: '8rem' }}>
          <Typography variant="h4" sx={{
                        mr: 2,
                        mt: 10,
                        // fontFamily: 'lato',
                        fontWeight: 700,
                        textDecoration: 'none',
                        color:"green"
                        
                      }} align="center">
            {t("FAQ")}
          </Typography>
          <p></p>
          <Typography variant="h6" align="center">
            {t("Do you have questions about our software? We have collected the most frequently asked ones below.")}
          </Typography>
          <p></p>
<FaqComponent></FaqComponent>  
      </Container>
      </div>
 <Footer></Footer>
    </div>
  );
}

export default FAQ;
