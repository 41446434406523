import React from 'react';

import Footer from '../../../Components/Info/Footer';
import Header from '../../../Components/Info/Header';
import InfoNavBar from '../../../Components/NavigationBar/InfoNavBar';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CookiesBanner from '../../../Components/Common/CookiesBanner';
import Description from '../../../Components/Info/Description';
import Summary from '../../../Components/Info/Summary';
import MainFunction from '../../../Components/Info/MainFunction';
import ContactForm from '../../../Components/Info/ContactForm';
import Lims from '../../../Components/Info/Lims';

const Info = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  return (
<div>
<InfoNavBar></InfoNavBar>
<CookiesBanner></CookiesBanner>
<Header></Header>
<p></p>
<Description></Description>
<p></p>
<MainFunction></MainFunction>
<p></p>
<Lims></Lims>
<p></p>
<Container maxWidth="md" style={{ marginTop: '2rem' }}>
<Typography variant="h4" sx={{
                        mr: 2,
                        mt: 2,
                        // fontFamily: 'lato',
                        fontWeight: 700,
                        textDecoration: 'none',
                        color:"green"
                        
                      }} align="center">
            {t("Contact form")}
          </Typography>
          <p></p>
          <Typography variant="h6" align="center">
            {t("Have questions or suggestions about our software? Contact the StreamlineLab team in any convenient way.")}
          </Typography>
<ContactForm></ContactForm>
</Container>
<p></p>
<Summary></Summary>

<p></p>
{/* <YouTubePlayer videoUrl="plXtqNL1Mh4" title={t('Check us out on Youtube')} /> */}

<p></p>
<Footer></Footer>

</div>
  );
};

export default Info;
